import cache from "common/lib/cache";
import traitify from "common/lib/traitify";
import {last} from "common/lib/ext/array";
import {dig} from "common/lib/ext/object";
import Locale from "./locale";
import I18nData from "./i18n-data.js.erb";

const parseOptions = (result, options) => {
  if(!result || typeof result !== "string") { return result; }

  return result.replace(/%\{[a-z_]*\}/g, (r) => options[r.slice(2, -1)]);
};

export default class I18n {
  constructor(locale, organization) {
    this.locale = locale;
    this.options = organization.options || {i18ns: {}};
    this.remote = {fetched: false, id: organization.uuid};
  }
  t(key, options = {}) {
    const enUS = Locale.fromCode("en-us");
    const value = this.#tDefault({key, locale: this.locale, options});
    if(this.locale.code === enUS.code) { return value; }

    return value || this.#tDefault({key, locale: enUS, options});
  }
  #fetchTranslations() {
    const key = `organization-translations-${this.remote.id}`;

    this.remote.fetched = true;
    this.remote.data = cache.get(key);
    if(this.remote.data) { return; }

    traitify.http.get("/xavier/translations", {project: "dash"}).then((data) => {
      if(data.error) {
        console.warn("translations", data); /* eslint-disable-line no-console */
        return;
      }

      cache.set(key, data, {expiresIn: 3600});
      this.remote.data = data;
      this.onLoad && this.onLoad();
    });
  }
  #tDefault({key, locale, options}) {
    const keys = key ? key.split(".") : [];
    const result = this.#tRemote({key, locale})
      || this.#tOption({key: last(keys), locale})
      || dig(I18nData[locale.rails_code], ...keys);

    return parseOptions(result, {...this.options, ...options});
  }
  #tOption({key: _field, locale}) {
    let field = _field;
    if(["department", "external_id", "package"].includes(field)) { field += "_label"; }

    const result = this.options.i18ns[locale.rails_code] || {};
    return result[field] || this.options[field];
  }
  #tRemote({key, locale}) {
    if(!this.remote.id) { return; }
    // NOTE: Remove when more keys are customized
    if(!key.startsWith("results.reports.")) { return; }
    if(!this.remote.fetched) { this.#fetchTranslations(); }

    const keys = key ? key.split(".") : [];
    return dig(this.remote.data, locale.code.toLowerCase(), ...keys);
  }
}
